/**
 * Slider Component
 *
 * @file slider.js
 */
 import $ from 'jquery';
 import Swiper from 'swiper';
 import SwiperCore, { Navigation, Pagination, Thumbs, Controller, EffectFade } from 'swiper/core';
 SwiperCore.use([Navigation, Pagination, Thumbs, Controller, EffectFade]);
 
 import 'swiper/swiper-bundle.css';
 import 'swiper/swiper-bundle.js';

const slider = (() => {
  /**
   * fullSlider
   * Full width slider settings
   */
  const fullSlider = () => {
    const swiper = new Swiper('.main-slider', {
      pagination: {
        el: ".home-slide-progress",
        type: "progressbar",
      },
      navigation: {
        nextEl: ".home-slide-right",
        prevEl: ".home-slide-left",
      },
      on: {
        slideChange: function(){
          var currentSlide = this.realIndex + 1;
          document.querySelector('.home-current-slide').innerHTML = currentSlide;
        },
        beforeInit: function(){
          let numOfSlides = this.wrapperEl.querySelectorAll(".main-slider__item").length;
          document.querySelector('.home-total-slides').innerHTML = numOfSlides;
        }
      }
    });
    if($(window).width() < 680){
      $(".main-slider__item").each(function(){
        var mobileSliderImage = $(this).attr("data-mobile-image");
        $(this).css('background-image', 'url(' + mobileSliderImage + ')');
      });
    }
  };

  const historySlider = () => {
    const historyNumberSlider = new Swiper('.history-number-slider', {
      direction: "vertical",
      spaceBetween: 0, 
      slidesPerView: 3,
      centeredSlides:true,
      watchSlidesProgress: true,
      preventInteractionOnTransition:true,
      breakpoints: {
        0: {
          slidesPerView: 2,
          direction: "horizontal",
          centeredSlides:false,
        },
        768: {
          slidesPerView: 4,
          direction: "horizontal",
          centeredSlides:false,
        },
        1024: {
          slidesPerView: 3,
        },
      },
    });
    const historySlider = new Swiper('.history-slider', {
      navigation: {
        nextEl: ".history-right",
        prevEl: ".history-left",
      },
      thumbs: {
        swiper: historyNumberSlider,
      }
    });
  }

  const productGallery = () => {
    const historyNumberSlider = new Swiper('.product-gallery-carousel', {
      spaceBetween: 13, 
      slidesPerView: 4,
      breakpoints: {
        0: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 2,
        },
        1024: {
          slidesPerView: 3,
        },
        1280: {
          slidesPerView: 4,
        },
      },
      navigation: {
        nextEl: ".pr-gallery-right",
        prevEl: ".pr-gallery-left",
      },
    });
  }

  const referenceGallery = () => {
    const historyNumberSlider = new Swiper('.reference-gallery-carousel', {
      spaceBetween: 40, 
      slidesPerView: 1.5,
      breakpoints: {
        0: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
        740: {
          slidesPerView: 2,
          spaceBetween: 15, 
        },
        1024: {
          slidesPerView: 1.5,
        },
      },
      navigation: {
        nextEl: ".ref-gallery-right",
        prevEl: ".ref-gallery-left",
      },
    });
  }

  const init = () => {
    fullSlider();
    historySlider();
    productGallery();
    referenceGallery();
  };

  return {
    init,
  };
})();

export default slider;
