/**
 * Header Layout
 *
 * @file header.js
 */


import $ from 'jquery';
const header = (() => {
  const fixedHeader = () => {
      if(!$(".home-slider").is(":visible")){
        $("header").addClass("fixed");
      } else {        
        $(window).scroll(function(){
          if($(window).scrollTop() > 50){
            $("header").addClass("fixed");
          } else{
            $("header").removeClass("fixed");
          }
        });
      }
  };
  const openMenuSearch = () => {
    const searchAreaBox = document.querySelector('#query');
    $(".menu-li").hover(function(){
      $(".menu-li").addClass("opacity");
      $(this).removeClass("opacity").find(".sub-menu").addClass("hover");
    }, function(){
      $(".menu-li").removeClass("opacity");
      $(this).find(".sub-menu").removeClass("hover");
    });
    $(".full-search-cl").on("click", function(){
      $(".full-search").addClass("active");
      $("html").addClass("overflow");
      if($("header").hasClass("fixed")){
        $(".full-search-close").addClass("fixed-header");
      } else{
        $(".full-search-close").removeClass("fixed-header");
      }
      setTimeout(function() { document.getElementById('query').focus(); }, 800);
    });
    $(".full-search-close").on("click", function(){
      $(".full-search").removeClass("active");
      $("html").removeClass("overflow");
      searchAreaBox.blur();
    });
    $(".mobile-menu-open").on("click", function(){
      $(this).toggleClass("active");
      $(".mobile-menu-toggle").toggleClass("active");
      $("html").toggleClass("overflow");
    });
  };

  const mobileMenu = () => {
    $(".mobil-mn li > a").on("click", function(){
      $(this).parent("li").toggleClass("active");
    });
    $(".mobile-sub-li").on("click", function(){
      $(this).toggleClass("active");
    });
  };

  const mobileDetailMenu = () => {
    $(".mobile-left-menu").on("click", function(){
      $(this).parent().find(".list").toggle(); 
    });
  };

  const init = () => {
    fixedHeader();
    openMenuSearch();
    mobileMenu();
    mobileDetailMenu();
  };

  return {
    init,
  };
})();

export default header;
