/**
 * @author Blueprint Frontend Team
 */
import "../scss/index.scss";
import { header } from './layouts';
import { slider, tabs, accordion,  lightbox } from './components';
import { applyForm } from './modules';

import { loadPage } from './utils';

(function () {
  loadPage.init();

  // Layouts
  header.init();


  // Partials Modules
  slider.init();
  tabs.init();
  accordion.init();
  applyForm.init();
  lightbox.init();


  // Page Modules
}());
